import Head from "next/head";
import { BlitzLayout } from "@blitzjs/next";

const Layout: BlitzLayout<{ title?: string }> = ({ title, children }) => {
  return (
    <>
      <Head>
        <title>{title || "fotosOpSchool"}</title>
        <link rel="icon" href="/Users/david/projects/hethuisvanvier/fotosopschool/public/favicon.ico" />
      </Head>

      {children}
    </>
  )
}

export default Layout
