import Image from "next/legacy/image"
import { Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslation } from "next-i18next"
import {
  Header as StyledHeader,
  HeaderBackground,
  HeaderCopy,
  HeaderCopyCaption,
  HeaderCopyExample,
  HeaderCopyText,
  ImageWrapper,
} from "./Header.styles"
import { PageContainer } from "src/app/core/components/PageContainer"
import { Nav } from "src/app/core/components/Nav"

export const Header = () => {
  const { t } = useTranslation("website")
  const theme = useTheme()
  const photoFactor = useMediaQuery(theme.breakpoints.up("md")) ? 3 : 2.3

  return (
    <StyledHeader>
      <HeaderBackground />
      <PageContainer>
        <Nav />
        <div className="headerContent" style={{ height: 500 }}>
          <HeaderCopy>
            <HeaderCopyText>
              <Typography variant="h1" color="white">
                {t("headerCopyTitle")}
              </Typography>

              <HeaderCopyCaption variant="h2" color="white">
                {t("headerCopyCaption")}
              </HeaderCopyCaption>
            </HeaderCopyText>

            <HeaderCopyExample>
              <ImageWrapper>
                <Image
                  src="/images/fos/fotos-op-school-dscf7793.webp"
                  alt={t("commonImageAlt")}
                  width={100 * photoFactor}
                  height={150 * photoFactor}
                  priority
                />
              </ImageWrapper>
            </HeaderCopyExample>
          </HeaderCopy>
        </div>
      </PageContainer>
    </StyledHeader>
  )
}
