import { styled, Typography } from "@mui/material"

export const Header = styled("div")(({ theme }) => ({
  position: "relative",
}))

export const HeaderBackground = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  left: "0",
  top: "0",
  right: "0",
  zIndex: "-1",
  // background:
  //   "linear-gradient(0deg,rgba(12,38,51,0) 12%,rgba(12,20,51,.75) 89%),linear-gradient(73deg,rgba(23,42,51,.9),rgba(87,158,194,.8) 61%),linear-gradient(252deg,#2a71bd 39%,#19293b)",
  background: theme.palette.primary.main,
  clipPath: "polygon(0 0,100% 0,100% calc(100% - 12vw),0 100%)",
}))

export const HeaderCopy = styled("div")({
  display: "grid",
  position: "relative",
  gridTemplateColumns: "repeat(12,1fr)",
  gridGap: 24,
  "@media screen and (max-width: 648px)": {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0",
  },
})
export const HeaderCopyText = styled("div")({
  gridColumn: "1/7",
  maxWidth: 478,
  paddingTop: 40,
  "@media screen and (max-width: 960px)": {
    paddingTop: 8,
  },
  "@media screen and (max-width: 648px)": {
    width: "100%",
    maxWidth: 470,
    margin: "auto",
    textAlign: "center",
    gridColumn: "1/1",
  },
})

export const HeaderCopyCaption = styled(Typography)({
  margin: "24px auto 24px",
  "@media screen and (max-width: 648px)": {
    marginLeft: "auto",
    maxWidth: "100 %",
  },
})

export const HeaderCopyExample = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gridColumn: "7/13",
  position: "relative",
})
export const ImageWrapper = styled("div")({
  position: "relative",
  margin: "0 auto",
  height: "100%",
  transform: "rotate(2deg)",
  // overflow: "hidden",
  "& > div": {
    boxShadow: "0 6px 30px rgba(15,21,26,.2)",
    borderRadius: "2%",
  },
})
