import { Header } from "./components/Header"
import { HomeSection } from "./components/HomeSection"
import { Footer } from "src/app/core/components/Footer"

export const HomePage = () => {
  return (
    <>
      <Header />
      <main>
        <HomeSection />
      </main>
      <Footer />
    </>
  )
}
