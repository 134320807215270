import { styled, Typography, TypographyProps } from "@mui/material"

type ServiceTitleProps = TypographyProps & { active?: boolean }

export const ServiceTitle = styled(({ active, ...props }: ServiceTitleProps) => (
  <Typography variant="h2" fontSize="3rem" {...props} />
))(({ theme, active }) => ({
  color: theme.palette.grey[400],
  transition: "color 0.2s ease",
  cursor: "pointer",
  borderLeft: `2px solid ${theme.palette.grey[400]}`,
  paddingLeft: theme.spacing(2),
  lineHeight: 1.5,
  ...(active && {
    color: theme.palette.text.primary,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  }),
  "&:hover": {
    color: theme.palette.text.primary,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
}))

type ServiceContentProps = TypographyProps & { active?: boolean }

export const ServiceContent = styled(({ active, ...props }: ServiceContentProps) => (
  <Typography textAlign="justify" pb={4} pt={2} {...props} />
))(({ theme, active }) => ({
  paddingLeft: theme.spacing(2),
  borderLeft: `2px solid ${theme.palette.primary.main}`,
  ...(active
    ? {
        display: "block",
      }
    : {
        display: "none",
      }),
}))

export const PhotoGrid = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr",
  gap: "16px 16px",
  gridAutoFlow: "row",

  [theme.breakpoints.down("md")]: {
    gap: "8px 8px",
  },
}))
