import { PageContainer } from "src/app/core/components/PageContainer"
import { HomeSectionWrapper } from "./HomeSection.styles"
import { Services } from "./Services"

export const HomeSection = () => {
  return (
    <HomeSectionWrapper>
      <PageContainer>
        <Services />
      </PageContainer>
    </HomeSectionWrapper>
  )
}
