import { styled } from "@mui/material"

const VideoWrap = styled("div")({
  float: "none",
  clear: "both",
  width: "100%",
  position: "relative",
  paddingBottom: "56.25%",
  paddingTop: "25px",
  height: "0",
})

const Iframe = styled("iframe")({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
})

type Props = {
  src: string
  title: string
}

export const ResponsiveYTVideo = ({ src, title }: Props) => {
  return (
    <VideoWrap>
      <Iframe
        src={src}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoWrap>
  )
}
