import { styled } from "@mui/material"

export const HomeSectionWrapper = styled("section")({
  paddingTop: 80,
  paddingBottom: 80,
  "@media screen and (max-width: 768px)": {
    paddingTop: 64,
  },
  "@media screen and (max-width: 648px)": {
    paddingTop: 240,
  },
})
